.claviyo_box {
input[type=text], input[type=email], input[type=tel],input[type=date], input[type=number], select, textarea {
    width: 100% !important;
    padding: 12px !important;
    border: 1px solid #05797C !important;
    border-radius: 4px !important;
    box-sizing: border-box !important;
    margin-top: 6px !important;
    margin-bottom: 16px !important;
    resize: vertical !important;
    font-family: 'Raleway' !important;
    font-size: 20px !important;
    color: #006169 !important;
    line-height: 1.5 !important;
    background-color: #fff;
    //height: 60px !important;
  }
}

  .contact-series{
      background: #fff;
      padding: 110px 0px;
      position: relative;
      overflow: hidden;
  }

  .font-size-24{
      font-size: 24px;
      line-height: 30px;
      font-weight: 600;
  }

  .email_text{
      font-size: 20px;
      line-height: 26px;
      font-weight: 600;
      text-decoration: underline;
      padding-top: 50px;
      color: #ff7900 !important;
  }

  .contact_services_text{
      font-size: 20px;
      line-height: 26px;
      font-weight: 600;
      padding-top: 30px;
  }

  .contact_map_text{
      background: #fff;
      padding: 0px 0px 50px;
      position: relative;
  }

  .contact_box{
      position: relative;
      background-color: #E3F1F1;
      padding: 15px 15px;
      border-radius: 15px;
      @media(min-width: 600px){
        padding: 15px 50px;
    }
  }

  .claviyo_box form label,
  .contact_box label{
      font-family: 'Raleway' !important;
      font-size: 20px !important;
      font-weight: 400 !important;
      color: #006169 !important;
  }

  .main_part_contact{
      margin-top: 35px;
  }

  .submit_btn{
    position: relative;
    width: 80%;
    margin: 20px auto;
    padding: 12px 24px;
    font-size: 18px;
    font-weight: bold;
    border: none;
    display: block;
    text-align: center;
    letter-spacing: 0.5px;
    color: #fff;
    border-radius: 23px;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    overflow: hidden;
    background-color: #ff7900;
    text-decoration: none;
    font-family: 'Raleway';
    z-index: 2;
}
