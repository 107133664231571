#cookie-notice {
    z-index: 1000;
    padding: 1rem;
    display: none;
    justify-content: space-around;
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    color: rgb(210, 210, 210);
    background-color: rgb(44, 44, 44);
    font-size: 16px;
    gap: 1rem;
  }

  @media (min-width: 768px) {
    #cookie-notice {
      grid-template-columns: 1fr max-content;
      padding: 1rem 8rem 1rem 1rem;
    }
  }

  #cookie-notice button,
  #cookie-notice a {
    display: inline-block;
    cursor: pointer;
    margin-left: 0.5rem;
    color: #fff;
    border-radius: 4px;
  }

  #cookie-notice button:hover,
  #cookie-notice a:hover {
    color: yellow !important;
  }

  #cookie-notice button.btn {
    background: #397439;
    padding: 5px 20px;
  }

  .btn-primary {
    background: darkgreen;
  }

  #cookie-notice span {
    max-width: 1000px;
    margin: auto;
    line-height: 1.3;
  }

  @media (max-width: 767px) {
    #cookie-notice span {
      display: block;
      padding-top: 3px;
      margin-bottom: 1rem;
    }

    #cookie-notice button {
      position: relative;
      bottom: 4px  
    }

    #cookie-notice a {
      position: relative;
      bottom: 4px;
    }
  }