.lunrsearchresult {
    padding-bottom: 1rem;
}

.lunrsearchresult .title {
    color: #006169;
    font-size: 1.2rem;
}

.lunrsearchresult .url {
    color: silver;
}

.lunrsearchresult a {
    display: block;
    color: #777;
}

.lunrsearchresult a:hover,
.lunrsearchresult a:focus {
    text-decoration: none;
}

.lunrsearchresult a:hover .title {
    text-decoration: underline;
}

.search_form {
    position: relative;
    z-index: 10;
    //width: 90%;
    max-width: 400px;
    margin: 0;

    .form-control {
        display: block;
        width: 100%;
        margin: auto;
        padding: 9px 20px;
        border: 1px #ddd solid;
        border-radius: 4px;
        @media(min-width: 600px){
        border-radius: 8px;}
    }
}

.mainsearch {
    background: rgb(232, 232, 232);

    #lunrsearchresults {
        width: 90%;
        z-index: 100;
        background: rgb(232, 232, 232);
        margin: 0 auto;
        ul {
            margin-bottom: 0;
        }

        #search_header {
            width: 80%;
            padding-top: 80px;
            display: grid;
            grid-template-columns: 1fr 40px;
    
            h3 {
                margin-bottom: 10px;
                padding: 20px;
            }

            #search_closer {
                padding: 10px;
                border-radius: 5px;
                width: fit-content;
                align-items: start;
                font-size: 2rem;
                &:hover {
                    cursor: pointer;
                    color: rgb(50, 53, 182);
                    background-color: #aac;
                }
                    }
        }

        p {
            padding: 10px 20px;
        }
    }

    .lunrsearchresults-list-item {
        font-weight: 400;
        list-style: decimal;
        list-style-position: outside;
        padding-bottom: 1rem;

        .search-result-title {
            color: rgb(2, 56, 2);
        }

        .url {
            color: silver;
        }

        a {
            display: block;
            color: #777;
        }

        a:hover,
        .lunrsearchresult a:focus {
            text-decoration: none;
        }

        a:hover .title {
            text-decoration: underline;
        }

        svg {
            display: none;
        }

        .search-result-highlight {
            font-weight: 800;
        }
    }

    .mb160 {
        margin: 0 auto 160px;
    }
}

.dark-mode .mainsearch {
    #lunrsearchresults {
        background: #000;

        a:hover {
            color: #aac !important;
        }
    }

    .lunrsearchresult .title {
        color: lightgred;
    }

    .lunrsearchresult .url {
        color: silver;
    }

    .lunrsearchresult a {
        display: block;
        color: #eee;
    }

    .search-form {
        .form-control {
            border: 1px #556 solid;
            background-color: #334;
            color: #ddd;
        }
    }

    #search_closer {
        &:hover {
            color: rgb(200, 200, 255);
        }
    }
}

.search_box {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
}