$backgroundColor: #ffffff;
$bodyColor: #000000;
$bodyFont: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";

body {
  background: $backgroundColor;
  color: $bodyColor;
  font-family: $bodyFont;
}

.hidden {
  display: none;
}

.markdown-page {
  min-height: 80vh;
  padding-top: 100px;
  padding-bottom: 80px;
  p {
    font-size: 20px;
    font-weight: 400;
    color: #008184;
    font-family: "Raleway";
  }

  .according p {
    font-size: 16px;
    color: rgb(0, 97, 105);
    line-height: 1.5;
    background: rgb(255, 255, 255);
    padding: 10px 135px 25px 30px;
    font-weight: 400;
  }

  table {
    th {
      width: 33%;
      margin: 0 auto;
      text-align: center;
      font-size: 1.2rem;
      font-weight: 700;
    }

    td, th {
      padding: 15px;
      display: table-cell;
      text-align: left;
      vertical-align: top;
      border-radius: 2px;
      border: 1px solid #ddd;
    }
  }
}